<template>
	<b-card class="Demo">
		<div v-if="step == 'start'" class="step">
			<div class="buttons">
				<button @click="step = 'lvns'" class="lvns">Liveness</button>
				<button @click="step = 'cmpr'" class="cmpr">Comparison</button>
				<div class="break" />
				<button @click="step = 'lvcmp'" class="lvcmp">Liveness + Comparison</button>
				<button @click="step = '1n'" class="onen">1-to-N</button>
			</div>
		</div>
		<div v-if="step == 'lvns'" class="step">
			<b-form-group label="Выберите фото с камеры" label-cols-md="4">
				<TypedField
					v-model="photo_a"
					:validation="true"
					:field="{
						base64: true,
						filter: 'image',
					}"
				/>
			</b-form-group>
			<b-form-group label="Проверка на размытость" label-cols-md="4">
				<TypedField
					v-model="settings.need_blurriness"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'boolean',
					}"
				/>
			</b-form-group>
			<b-form-group label="Должно ли быть не более одного лица?" label-cols-md="4">
				<TypedField
					v-model="settings.single_face_only"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'boolean',
					}"
				/>
			</b-form-group>
			<b-form-group label="Порог открытости глаз" label-cols-md="4">
				<TypedField
					v-model="settings.blink_threshold"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'number',
					}"
				/>
			</b-form-group>
			<b-form-group label="Должно ли быть фото цветным?" label-cols-md="4">
				<TypedField
					v-model="settings.color_image_only"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'boolean',
					}"
				/>
			</b-form-group>
			<b-form-group label="Нужна ли проверка на поворот головы?" label-cols-md="4">
				<TypedField
					v-model="settings.head_rotation"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'boolean',
					}"
				/>
			</b-form-group>

			<div class="controls">
				<b-button @click="step = 'start'" variant="outline-secondary">Назад</b-button>&nbsp;
				<b-button @click="exec('lvns')" variant="primary">Далее</b-button>
			</div>
		</div>
		<div v-if="step == 'cmpr'" class="step">
			<b-form-group label="Выберите фото с камеры" label-cols-md="4">
				<TypedField
					v-model="photo_a"
					:validation="true"
					:field="{
						base64: true,
						filter: 'image',
					}"
				/>
			</b-form-group>
			<b-form-group label="Выберите фото для сравнения" label-cols-md="4">
				<TypedField
					v-model="photo_b"
					:validation="true"
					:field="{
						base64: true,
						filter: 'image',
					}"
				/>
			</b-form-group>
			<b-form-group label="Порог схожести" label-cols-md="4">
				<TypedField
					v-model="settings.comparison_threshold"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'number',
					}"
				/>
			</b-form-group>

			<div class="controls">
				<b-button @click="step = 'start'" variant="outline-secondary">Назад</b-button>&nbsp;
				<b-button @click="exec('cmpr')" variant="primary">Далее</b-button>
			</div>
		</div>
		<div v-if="step == 'lvcmp'" class="step">
			<b-form-group label="Выберите фото с камеры" label-cols-md="4">
				<TypedField
					v-model="photo_a"
					:validation="true"
					:field="{
						base64: true,
						filter: 'image',
					}"
				/>
			</b-form-group>
			<b-form-group label="Выберите фото для сравнения" label-cols-md="4">
				<TypedField
					v-model="photo_b"
					:validation="true"
					:field="{
						base64: true,
						filter: 'image',
					}"
				/>
			</b-form-group>
			<b-form-group label="Проверка на размытость" label-cols-md="4">
				<TypedField
					v-model="settings.need_blurriness"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'boolean',
					}"
				/>
			</b-form-group>
			<b-form-group label="Должно ли быть не более одного лица?" label-cols-md="4">
				<TypedField
					v-model="settings.single_face_only"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'boolean',
					}"
				/>
			</b-form-group>
			<b-form-group label="Порог открытости глаз" label-cols-md="4">
				<TypedField
					v-model="settings.blink_threshold"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'number',
					}"
				/>
			</b-form-group>
			<b-form-group label="Должно ли быть фото цветным?" label-cols-md="4">
				<TypedField
					v-model="settings.color_image_only"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'boolean',
					}"
				/>
			</b-form-group>
			<b-form-group label="Нужна ли проверка на поворот головы?" label-cols-md="4">
				<TypedField
					v-model="settings.head_rotation"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'boolean',
					}"
				/>
			</b-form-group>
			<b-form-group label="Порог схожести" label-cols-md="4">
				<TypedField
					v-model="settings.comparison_threshold"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'number',
					}"
				/>
			</b-form-group>

			<div class="controls">
				<b-button @click="step = 'start'" variant="outline-secondary">Назад</b-button>&nbsp;
				<b-button @click="exec('lvcmp')" variant="primary">Далее</b-button>
			</div>
		</div>
		<div v-if="step == '1n'" class="step">
			<b-form-group label="Выберите фото с камеры" label-cols-md="4">
				<TypedField
					v-model="photo_a"
					:validation="true"
					:field="{
						base64: true,
						filter: 'image',
					}"
				/>
			</b-form-group>
			<b-form-group label="Порог схожести" label-cols-md="4">
				<TypedField
					v-model="settings.comparison_threshold"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'number',
					}"
				/>
			</b-form-group>
			<b-form-group label="Кол-во результатов" label-cols-md="4">
				<TypedField
					v-model="settings.results"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'number',
					}"
				/>
			</b-form-group>
			<b-form-group label="ID группы" label-cols-md="4">
				<TypedField
					v-model="settings.group_id"
					:validation="true"
					:inline="true"
					:field="{
						filter: 'number',
					}"
				/>
			</b-form-group>

			<div class="controls">
				<b-button @click="step = 'start'" variant="outline-secondary">Назад</b-button>&nbsp;
				<b-button @click="exec('1n')" variant="primary">Далее</b-button>
			</div>
		</div>
		<div v-if="step == 'lvns_result'" class="step">
			<center style="padding: 3em 0; font-size: 1.5em">
				<b-spinner variant="primary" v-if="!result" />
				<div>Запрос: {{ result.status == 200 ? 'OK' : 'Fail' }}</div>
				<div v-if="result.data.state">Результат: {{ result.data.state.message }}</div>
				<div v-if="result.data.data">Liveness: {{ result.data.data.is_alive ? 'OK' : 'Not passed' }}</div>
				<br />
				<div v-if="result">Вы можете проверить результат в таблице запросов:</div>
			</center>
			<div class="controls">
				<b-button @click="step = 'lvns'" variant="outline-secondary">Назад</b-button>&nbsp;
				<b-button @click="() => $router.push({ name: 'queries' })" variant="primary">Проверить</b-button>
			</div>
		</div>
		<div v-if="step == 'cmpr_result'" class="step">
			<center style="padding: 3em 0; font-size: 1.5em">
				<b-spinner variant="primary" v-if="!result" />
				<div>Запрос: {{ result.status == 200 ? 'OK' : 'Fail' }}</div>
				<div v-if="result.data.state">Результат: {{ result.data.state.message }}</div>
				<div v-if="result.data.data">
					Comparison: {{ result.data.data.is_recognized ? 'OK' : 'Not passed' }}
				</div>
				<br />
				<div v-if="result">Вы можете проверить результат в таблице запросов:</div>
			</center>
			<div class="controls">
				<b-button @click="step = 'cmpr'" variant="outline-secondary">Назад</b-button>&nbsp;
				<b-button @click="() => $router.push({ name: 'queries' })" variant="primary">Проверить</b-button>
			</div>
		</div>
		<div v-if="step == 'lvcmp_result'" class="step">
			<center style="padding: 3em 0; font-size: 1.5em">
				<b-spinner variant="primary" v-if="!result" />
				<div>Запрос: {{ result.status == 200 ? 'OK' : 'Fail' }}</div>
				<div v-if="result.data.state">Результат: {{ result.data.state.message }}</div>
				<div v-if="result.data.data">Liveness: {{ result.data.data.is_alive ? 'OK' : 'Not passed' }}</div>
				<div v-if="result.data.data">
					Comparison: {{ result.data.data.is_recognized ? 'OK' : 'Not passed' }}
				</div>
				<br />
				<div v-if="result">Вы можете проверить результат в таблице запросов:</div>
			</center>
			<div class="controls">
				<b-button @click="step = 'lvcmp'" variant="outline-secondary">Назад</b-button>&nbsp;
				<b-button @click="() => $router.push({ name: 'queries' })" variant="primary">Проверить</b-button>
			</div>
		</div>
		<div v-if="step == '1n_result'" class="step">
			<center style="padding: 3em 0; font-size: 1.5em">
				<b-spinner variant="primary" v-if="!result" />
				<div>Запрос: {{ result.status == 202 ? 'Accepted' : 'Fail' }}</div>
				<div v-if="result.data.state">Результат: {{ result.data.state.message }}</div>
				<div v-if="result">Вы можете проверить результат в таблице запросов.</div>
				<div v-if="result">Task ID: {{ result.data.task_id }}</div>
			</center>
			<div class="controls">
				<b-button @click="step = '1n'" variant="outline-secondary">Назад</b-button>&nbsp;
				<b-button @click="() => $router.push({ name: 'oneton' })" variant="primary">Проверить</b-button>
			</div>
		</div>
		<div v-if="step == 'check'" class="step"></div>
	</b-card>
</template>

<script>
import TypedField from '@/components/TypedField.vue'
import axiosIns from '@/libs/axios'
import { reactive, ref, watchEffect } from '@vue/composition-api'
import axios from 'axios'
import { BCard } from 'bootstrap-vue'
import { BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BSpinner } from 'bootstrap-vue'
import { APIRoot } from '@/api/schema'
export default {
	name: 'Demo',
	components: {
		BCard,
		BRow,
		BCol,
		BFormGroup,
		BFormInput,
		BFormCheckbox,
		BForm,
		BButton,
		BSpinner,
		TypedField,
	},
	setup() {
		const $ = {
			step: ref('start'),
			photo_a: ref(null),
			photo_b: ref(null),
			settings: reactive({
				head_rotation: true,
				blink_threshold: 0.5,
				need_blurriness: true,
				color_image_only: true,
				single_face_only: true,
				comparison_threshold: 0.5,
				results: 10,
				group_id: 'test',
			}),
			result: ref(null),
			task_id: ref(''),
			person_id: ref('AA098765512'),
			external_id: ref('7830778512'),
		}

		$.exec = async function(e) {
			if (e == 'lvns') {
				$.step.value = 'lvns_result'
				const result = await axiosIns.post(APIRoot + 'verification-sync/', {
					camera_image: $.photo_a.value,
					external_id: $.external_id.value,
					person_id: $.person_id.value,
					request_type: 'liveness_only',
					settings: {
						head_rotation: $.settings.head_rotation,
						blink_threshold: +$.settings.blink_threshold,
						need_blurriness: $.settings.need_blurriness,
						color_image_only: $.settings.color_image_only,
						single_face_only: $.settings.single_face_only,
						comparison_threshold: +$.settings.comparison_threshold,
					},
				})
				$.result.value = result
				$.task_id.value = result.status
			} else if (e == 'cmpr') {
				$.step.value = 'cmpr_result'
				const result = await axiosIns.post(APIRoot + 'verification-sync/', {
					camera_image: $.photo_a.value,
					id_image: $.photo_b.value,
					external_id: $.external_id.value,
					person_id: $.person_id.value,
					request_type: 'comparison_only',
					settings: {
						comparison_threshold: +$.settings.comparison_threshold,
					},
				})
				$.result.value = result
				$.task_id.value = result.status
			} else if (e == 'lvcmp') {
				$.step.value = 'lvcmp_result'
				const result = await axiosIns.post(APIRoot + 'verification-sync/', {
					camera_image: $.photo_a.value,
					id_image: $.photo_b.value,
					external_id: $.external_id.value,
					person_id: $.person_id.value,
					request_type: 'liveness_comparison',
					settings: {
						head_rotation: $.settings.head_rotation,
						blink_threshold: +$.settings.blink_threshold,
						need_blurriness: $.settings.need_blurriness,
						color_image_only: $.settings.color_image_only,
						single_face_only: $.settings.single_face_only,
						comparison_threshold: +$.settings.comparison_threshold,
					},
				})
				$.result.value = result
				$.task_id.value = result.status
			} else if (e == '1n') {
				$.step.value = '1n_result'
				const result = await axiosIns.post(APIRoot + '1-to-n/search/', {
					camera_photo: $.photo_a.value,
					settings: {
						comparison_threshold: +$.settings.comparison_threshold,
						results: +$.settings.results,
						group_id: $.settings.group_id,
					},
				})
				$.result.value = result
				$.task_id.value = result.status
			}
		}

		return $
	},
}
</script>

<style scoped>
.Demo {
	max-width: 50em;
	margin: 0 auto;
}
.buttons {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 2em;
}
.buttons button {
	border: none;
	background-color: none;
	width: 20rem;
	min-height: 16rem;
	border-radius: 0.5em;
	font-size: 2em;
	letter-spacing: 0.1em;
}
.buttons .break {
	min-width: 100%;
	flex-grow: 100000;
	display: block;
}

.lvns {
	background: #bdff8b;
}

.cmpr {
	background: #00cfff;
}

.lvcmp {
	background: #71ffc7;
}

.onen {
	background: #9999ff;
}

.controls {
	text-align: right;
}
</style>
